import { moduleLogger } from "../util";

export const log = moduleLogger.scope("storefront");

export function getFeatureOrder(me: HTMLElement | null): number {
	const order = me?.parentElement?.dataset.featureOrder ?? "99";
	if (!order) {
		log.error("Storefront Cinema order does not exist");
		return 99;
	}

	const orderInt = Number.parseInt(order);
	if (Number.isNaN(orderInt)) {
		log.error("Storefront Cinema order is not a number");
		return 99;
	}
	return orderInt;
}
